/* eslint-disable no-useless-constructor */
import feathersClient, { makeServicePlugin, BaseModel } from '../../api'
import moment from 'moment'
import pdfTemplateTypes from '@/resources/enums/pdfTemplateTypes'

const system = process.env.VUE_APP_SYSTEM
let country
let mobilePrefix
if (system === 'at') {
  country = () => ({ name: 'Österreich', char: 'A' })
  mobilePrefix = '0043'
} else {
  country = () => ({ name: 'Deutschland', char: 'D' })
  mobilePrefix = '0049'
}

class Order extends BaseModel {
  constructor (data, options) {
    super(data, options)
  }

  // Required for FeathersVuex plugin to work after production transpile.
  static modelName = 'Order'
  // Define default properties here
  static instanceDefaults () {
    return {
      owner: {
        type: 'PRIVATE',
        title: {
        },
        city: {
        },
        country: country(),
        mobilePrefix
      },
      contract: {
        applicationDate: moment().toDate()
      },
      boat: {
        country: country()
      },
      car: {
        country: country(),
        noHsnTsn: false,
        milageMeasureDate: moment().toDate(),
        gasUpcharge: false
      },
      product: {
        rechnungHalter: false,
        ratenZahlung: false,
        contract: false
      },
      attachments: [],
      mobilityRate: {
        start: moment().toDate()
      },
      sepa: {
        accept: false
      },
      gba: false,
      isHandedOut: false,
      isContract: false,
      doc: {
        template: pdfTemplateTypes.ORDER.api
      }
    }
  }
}
const servicePath = 'orders'
const servicePlugin = makeServicePlugin({
  Model: Order,
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
