import store from './store/'
const publicPageNames = [
  'Login',
  'PasswordForgot',
  'PasswordReset',
  'PasswordCreate',
  'LoginTraderId',
  '2faU2F',
  '2faTOTP',
  '2faSMS',
  '2faSelect',
  '2faSetup'
]

export default async function authenticationGuard (to, from, next) {
  const { auth } = store.state
  const isLoggedIn = store.getters['auth/isAuthenticated']
  const is2fa = to.name === '2faU2F' || to.name === '2faTOTP' || to.name === '2faSMS' || to.name === '2faSelect' || to.name === '2faSetup'
  if (isLoggedIn && !auth.payload.is2fa && !is2fa && to.name !== 'Login') {
    const client = (await store.dispatch('clients/find')).data[0]
    const twoFactorRoute = checkTwoFactor(to, auth.user.user, client)
    if (twoFactorRoute) {
      return next(twoFactorRoute)
    }
  }

  if (isLoggedIn) {
    return redirectToHome(auth, to, next)
  }
  try {
    await store.dispatch('auth/authenticate')
    if (!auth.payload.is2fa && !is2fa && to.name !== 'Login') {
      const client = (await store.dispatch('clients/find')).data[0]

      const twoFactorRoute = checkTwoFactor(to, auth.user.user, client)
      if (twoFactorRoute) {
        return next(twoFactorRoute)
      }
    }
    // if (auth.payload.is2fa && is2fa) {
    //   return next('/' || to.query.redirect)
    // }
    redirectToHome(auth, to, next)
  } catch (exception) {
    const toPublicPage = publicPageNames.includes(to.name)
    if (toPublicPage) return next()
    console.error(exception)
    return next({ path: '/login', query: { redirect: to.path, ...to.query } })
  }
}

function checkTwoFactor (to, user, client) {
  const { twoFactor, role } = user
  const U2F = twoFactor && twoFactor.U2F && twoFactor.U2F.publicKey && twoFactor.U2F.keyHandle
  const U2FEnabled = client && client.twoFactor && client.twoFactor.U2F && client.twoFactor.U2F.enabled

  const TOTP = twoFactor && twoFactor.TOTP && twoFactor.TOTP.secret
  const TOTPEnabled = client && client.twoFactor && client.twoFactor.TOTP && client.twoFactor.TOTP.enabled

  const SMS = twoFactor && twoFactor.SMS && twoFactor.SMS.secret
  const SMSEnabled = client && client.twoFactor && client.twoFactor.SMS && client.twoFactor.SMS.enabled

  const query = { redirect: to.path }
  if (U2F && U2FEnabled) return { name: '2faU2F', query }
  if (TOTP && TOTPEnabled) return { name: '2faTOTP', query }
  if (SMS && SMSEnabled) return { name: '2faSMS', query }
  if (client.twoFactorRoles && client.twoFactorRoles.includes(role)) return { name: '2faSetup', query }
}

function redirectToHome (auth, to, next) {
  if (to.name === 'Home') {
    if (auth.user.role.scenarioIds.includes('seeOwnDashboard')) {
      return next({ name: 'Dashboard' })
    } else {
      return next({ name: 'HSSOrderCreate' })
    }
  } else {
    return next()
  }
}
