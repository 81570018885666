export default [
  {
    path: '/',
    component: () => import('../../layouts/CenterLayout.vue'),
    children: [
      {
        name: 'Login',
        path: '/login',
        component: () => import('./Login.vue')
      },
      {
        name: 'PasswordForgot',
        path: '/login/password-forgot',
        component: () => import('./PasswordForgot')
      },
      {
        name: 'PasswordReset',
        path: '/login/password-reset/:passwordResetToken',
        component: () => import('./PasswordReset')
      },
      {
        name: 'PasswordCreate',
        path: '/login/password-create/:passwordResetToken',
        component: () => import('./PasswordReset'),
        meta: {
          isCreate: true
        }
      },
      {
        name: 'LoginTraderId',
        path: '/login/:traderId',
        component: () => import('./Login.vue'),
        meta: {
          bypassTraderId: true
        }
      },
      {
        name: '2faU2F',
        path: '/2fa/u2f',
        component: () => import('./2faU2F')
      },
      {
        name: '2faTOTP',
        path: '/2fa/totp',
        component: () => import('./2faTOTP')
      },
      {
        name: '2faSMS',
        path: '/2fa/sms',
        component: () => import('./2faSMS')
      },
      {
        name: '2faSelect',
        path: '/2fa/select',
        component: () => import('./2faSelect')
      },
      {
        name: '2faSetup',
        path: '/2fa/setup',
        component: () => import('./2faSetup')
      }
    ]
  }
]
