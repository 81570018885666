import hsOrderTypes from '@/resources/enums/hsOrderTypes'
export default [
  {
    path: '/order/',
    redirect: 'order/list'
  },
  {
    name: 'OrderList',
    path: '/order/list',
    component: () => import('./OrderList.vue')
  },
  {
    name: 'OrderListUser',
    path: '/order/user/:userId/all',
    component: () => import('./OrderList.vue'),
    meta: {
      isOverview: true
    }
  },
  {
    name: 'OrderGvcListUser',
    path: '/order/user/:userId/gvc',
    component: () => import('./OrderGvcList.vue'),
    meta: {
      isOverview: true
    }
  },
  {
    name: 'OrderGvcList',
    path: '/order/gvc/all',
    component: () => import('./OrderGvcList.vue')
  },
  {
    name: 'OrderExtendListUser',
    path: '/order/user/:userId/extend',
    component: () => import('./OrderExtendList.vue'),
    meta: {
      isOverview: true
    }
  },
  {
    name: 'OrderExtendList',
    path: '/order/extend/all',
    component: () => import('./OrderExtendList.vue')
  },
  {
    name: 'HSSOrderCreate',
    path: '/hssorder/create',
    component: () => import('./HSSOrderCreate.vue')
  },
  {
    name: 'HSSAntragList',
    path: '/hssorder/orders/:type?',
    component: () => import('./HSSOrderList.vue'),
    meta: {
      hsOrderType: hsOrderTypes.ANTRAG.api
    }
  },
  {
    name: 'HSSAnfrageList',
    path: '/hssorder/orders/:type?',
    component: () => import('./HSSOrderList.vue'),
    meta: {
      hsOrderType: hsOrderTypes.ANFRAGE.api
    }
  },
  {
    name: 'HSSVertragList',
    path: '/hssorder/orders/:type?',
    component: () => import('./HSSOrderList.vue'),
    meta: {
      hsOrderType: hsOrderTypes.VERTRAG.api
    }
  },
  {
    name: 'HSSAngebotList',
    path: '/hssorder/orders/:type?',
    component: () => import('./HSSOrderList.vue'),
    meta: {
      hsOrderType: hsOrderTypes.ANGEBOT.api
    }
  },
  {
    name: 'HSSOrderDetail',
    path: '/hssorder/:orderId',
    component: () => import('./HSSOrderDetail.vue')
  },
  {
    name: 'HSSOrderEdit',
    path: '/hssorder/:orderId/edit',
    component: () => import('./HSSOrderEdit.vue')
  },
  {
    name: 'HSSOrderNachtrag',
    path: '/hssorder/:orderId/nachtrag',
    component: () => import('./HSSOrderNachtrag.vue')
  },
  {
    name: 'OrderCreate',
    path: '/order/create/:carId?/:rateId?',
    component: () => import('./OrderCreate.vue')
  },
  {
    name: 'OrderKaufschutzCreate',
    path: '/order/createKaufschutz/:carId?/:rateId?',
    component: () => import('./OrderKaufschutzCreate.vue')
  },
  {
    name: 'OrderBoatCreate',
    path: '/order/createBoot',
    component: () => import('./OrderBoatCreate.vue')
  },
  {
    name: 'OrderDetail',
    path: '/order/:orderId',
    component: () => import('./OrderDetail.vue')
  },
  {
    name: 'OrderDetailOrderIdExtend',
    path: '/order/:orderId/extend',
    component: () => import('./OrderExtend.vue')
  },
  {
    name: 'OrderEdit',
    path: '/order/:orderId/edit',
    component: () => import('./OrderEdit.vue')
  },
  {
    name: 'HSSOrderAngebotEdit',
    path: '/order/:orderId/edit/angebot',
    component: () => import('./HSSOrderAngebotEdit.vue')
  },
  {
    name: 'HSSOrderAntragEdit',
    path: '/order/:orderId/edit/antrag',
    component: () => import('./HSSOrderAntragEdit.vue')
  },
  {
    name: 'OrderExtend',
    path: '/order/:orderId/extend',
    component: () => import('./OrderExtend.vue')
  }
]
