<template>
  <div class="d-flex">
    <side-menu @collapse="collapsed = $event" />
    <div
      :class="{ 'collapsed': collapsed }"
      class="content"
    >
      <div class="body">
        <component :is="layout">
          <router-view />
        </component>
      </div>
      <layout-footer />
    </div>
  </div>
</template>

<script>
import SideMenu from '@/components/Layout/SideMenu'
import LayoutFooter from '@/components/Layout/LayoutFooter.vue'

/* load alert components via require context (https://webpack.js.org/guides/dependency-management/#require-context) */
const requireLayoutComponents = require.context(
  '.', false, /.\.vue/
)
const LayoutComponents = requireLayoutComponents.keys()
  .map(filename => requireLayoutComponents(filename).default)
export default {
  name: 'DefaultLayout',
  components: {
    SideMenu,
    LayoutFooter
  },
  data () {
    return {
      collapsed: null
    }
  },
  computed: {
    layout () {
      return LayoutComponents
        .filter(component => component !== undefined)
        .find(component => component.name === this.$route.meta.layout) || 'div'
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";

  .content {
    width: calc(100% - #{$sidebar-submenu-with}) !important;
    margin-left: $sidebar-submenu-with;
    transition: all 0.3s ease;
    //Margin Höhe der Navbar
    //margin-top: $topNavHeight;
    .body{
      min-height: calc(100vh - 51px);
    }

    &.collapsed {
      //Breite der Sidebars im collapsend zustand
      width: calc(100% - 50px) !important;
      margin-left: 50px;
    }
  }

  .sidebar-wrapper {
    height: 100%;
    position: fixed;
  }
</style>
