export default [
  {
    name: 'RateList',
    path: '/rate',
    component: () => import('./RateList'),
    meta: {
      layout: 'RateListsLayout'
    }
  },
  {
    name: 'RateCreate',
    path: '/rate/create',
    component: () => import('./RateCreate')
  },
  {
    name: 'RateEdit',
    path: '/rate/:rateId',
    component: () => import('./RateEdit')
  }
]
