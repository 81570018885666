import store from '@/store'

export default {
  computed: {
    $can () {
      const account = store.getters['auth/user']
      if (!account.role) return false
      const scenarioIds = account.role.scenarioIds
      return (scenarioId) => {
        return scenarioIds.includes(scenarioId)
      }
    }
  }
}
