module.exports = {
  TK0: { api: 'TK0', human: 'TK 0€', tk: 0 },
  TK150: { api: 'TK150', human: 'TK 150€', tk: 150 },
  TK300: { api: 'TK300', human: 'TK 300€', tk: 300 },
  TK500: { api: 'TK500', human: 'TK 500€', tk: 500 },
  TK1000: { api: 'TK1000', human: 'TK 1.000€', tk: 1000 },
  TK2500: { api: 'TK2500', human: 'TK 2.500€', tk: 2500 },
  TK5000: { api: 'TK5000', human: 'TK 5.000€', tk: 5000 },
  TK10000: { api: 'TK10000', human: 'TK 10.000€', tk: 10000 },
  TK25000: { api: 'TK25000', human: 'TK 25.000€', tk: 25000 },
  TK50000: { api: 'TK50000', human: 'TK 50.000€', tk: 50000 },
  TK100000: { api: 'TK100000', human: 'TK 100.000€', tk: 100000 }
}
