/* eslint-disable no-useless-constructor */
import feathersClient, { makeServicePlugin, BaseModel } from '../../api'

class AgbModule extends BaseModel {
  constructor (data, options) {
    super(data, options)
  }

  // Required for FeathersVuex plugin to work after production transpile.
  static modelName = 'AgbModule'
}
const servicePath = 'agb-module'
const servicePlugin = makeServicePlugin({
  Model: AgbModule,
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
