module.exports = {
  '': { api: '', file: '' },
  AGB: { api: 'AGB', file: 'agbs/agb.html' },
  ORDER: { api: 'ORDER' },
  HS_ORDER_ANTRAG: { api: 'HS_ORDER_ANTRAG' },
  HS_ORDER_ANFRAGE: { api: 'HS_ORDER_ANFRAGE' },
  HS_ORDER_ANGEBOT: { api: 'HS_ORDER_ANGEBOT' },
  HS_ORDER_VERTRAG: { api: 'HS_ORDER_VERTRAG' },
  SEPA_MANDAT: { api: 'SEPA_MANDAT' },
  HS_ORDER_ZWISCHENANTRAG: { api: 'HS_ORDER_ZWISCHENANTRAG' },
  HS_ORDER_NACHTRAG: { api: 'HS_ORDER_NACHTRAG' },
  HS_ORDER_AUFHEBUNGSNACHTRAG: { api: 'HS_ORDER_AUFHEBUNGSNACHTRAG' },
  HS_ORDER_RUHENACHTRAG: { api: 'HS_ORDER_RUHENACHTRAG' },
  HS_ORDER_BERATUNGSPROTOKOLL: { api: 'HS_ORDER_BERATUNGSPROTOKOLL' }
}
