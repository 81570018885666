import store from './store/'
import router from './router'
let timeout
store.subscribe((mutation) => {
  if (mutation.type === 'auth/setPayload') {
    const accessTokenDecoded = Buffer.from(mutation.payload.accessToken.split('.')[1], 'base64') // decode second part of jwt (object with informations like expire)
    const accesTokenObj = JSON.parse(accessTokenDecoded) // convert to Json Object
    const msUntiLogout = accesTokenObj.exp * 1000 - Date.now() // accesstoken = seconds date.now = ms
    if (timeout) clearInterval(timeout)
    timeout = setTimeout(async () => {
      store.dispatch('auth/logout')
      router.push({ name: 'Login', query: { tokenExpired: Date.now() } })
    }, msUntiLogout) // 3h - expiresIn of jwt token
  }
})
