export default [
  {
    path: '/resubmission/',
    redirect: 'resubmission/list'
  },
  {
    name: 'ResubmitList',
    path: '/resubmission/list',
    component: () => import('./ResubmitList')
  },
  {
    name: 'ResubmitCreate',
    path: '/resubmission/create',
    component: () => import('./ResubmitCreate')
  },
  {
    // params: hsOrderId customerId
    name: 'ResubmitDetail',
    path: '/resubmission/:resubmitId',
    component: () => import('./ResubmitDetail')
  },
  {
    name: 'ResubmitEdit',
    path: '/resubmission/edit/:resubmitId',
    component: () => import('./ResubmitEdit')
  }
]
