export default [
  {
    path: '/workshop/',
    redirect: 'workshop/list'
  },
  {
    name: 'WorkshopList',
    path: '/workshop/list',
    component: () => import('./WorkshopList')
  },
  {
    name: 'WorkshopCreate',
    path: '/workshop/create',
    component: () => import('./WorkshopCreate')
  },
  {
    name: 'WorkshopDetail',
    path: '/workshop/:workshopId',
    component: () => import('./WorkshopDetail')
  },
  {
    name: 'WorkshopEdit',
    path: '/workshop/:workshopId/edit',
    component: () => import('./WorkshopEdit')
  }
]
