import Vue from 'vue'
import Router from 'vue-router'

import DefaultLayout from './layouts/DefaultLayout.vue'

import AgbRoutes from './views/Agb/_routes'
import DamageReportRoutes from './views/DamageReport/_routes'
import BlacklistedChassisRoutes from './views/BlacklistedChassis/_routes'
import LoginRoutes from './views/Login/_routes'
import OrderRoutes from './views/Order/_routes'
import RateRoutes from './views/Rate/_routes'
import KaufschutzRateRoutes from './views/KaufschutzRate/_routes'
import HsnTsnRoutes from './views/HsnTsn/_routes'
import SystemRoutes from './views/System/_routes'
import UserRoutes from './views/User/_routes'
import NewsRoutes from './views/News/_routes'
import CarRoutes from './views/Car/_routes'
import WorkshopRoutes from './views/Workshop/_routes'
import ResubmissionRoutes from './views/Resubmission/_routes'
import Dashboard from './views/Dashboard/_routes'
import HSSDataEdit from './views/HSSDataEdit/_routes'
import CustomerRoutes from './views/Customer/_routes'
import AgentGroupRoutes from './views/AgentGroups/_routes'
import VersichererabrechnungRouters from './views/Versichererabrechnung/_route'

import authenticationGuard from './authenticationGuard'
import redirectHook from './redirectHook'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: DefaultLayout,
      children: [
        ...AgbRoutes,
        ...DamageReportRoutes,
        ...BlacklistedChassisRoutes,
        ...OrderRoutes,
        ...RateRoutes,
        ...KaufschutzRateRoutes,
        ...HsnTsnRoutes,
        ...SystemRoutes,
        ...UserRoutes,
        ...NewsRoutes,
        ...CarRoutes,
        ...WorkshopRoutes,
        ...ResubmissionRoutes,
        ...Dashboard,
        ...HSSDataEdit,
        ...CustomerRoutes,
        ...AgentGroupRoutes,
        ...VersichererabrechnungRouters
      ]
    },
    ...LoginRoutes,
    {
      path: '/vertrag/:warrentyNumber',
      beforeEnter: redirectHook
    },
    {
      name: 'PushNotificationRedirect',
      path: '/push-notification/:notificationId',
      component: () => import('@/views/PushNotificationRedirect')
    },
    {
      name: 'Redirect',
      path: '*',
      redirect: '/hssorder/create'
    }
  ]
})
router.beforeEach(authenticationGuard)
export default router
