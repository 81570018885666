<template>
  <div
    class="w-100 wrapper"
    :class="[`bg-${variant}-gradient`, {'rounded': rounded}, `px-${padding}`]"
  >
    <div class="row align-items-center">
      <div
        v-if="icon !== 'none'"
        class="col-auto pr-0"
      >
        <i
          class="fas fa-lg text-white"
          :class="[`fa-${getIcon()}`, {'fa-spin': spinIcon}]"
        />
      </div>
      <div class="col my-2 text-white">
        <slot>
          {{ message }}
        </slot>
      </div>
      <div
        v-if="!notCloseable"
        class="col-auto"
      >
        <button
          class="btn btn-link text-white pr-0"
          @click="close()"
        >
          <i class="fas fa-times" />
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.wrapper{
  z-index: 1080;
}
</style>

<script>
export default {
  name: 'Alert',
  props: {
    variant: {
      type: String,
      default: 'primary'
    },
    icon: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    rounded: {
      type: Boolean,
      default: false
    },
    spinIcon: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: true
    },
    notCloseable: {
      type: Boolean,
      default: false
    },
    padding: {
      type: Number,
      default: 4
    }
  },
  data () {
    return {
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    getIcon () {
      if (this.variant && !this.icon) {
        switch (this.variant) {
        case 'primary':
          return 'sparkles'
        case 'info':
          return 'info'
        case 'success':
          return 'check'
        case 'warning':
          return 'exclamation'
        case 'danger':
          return 'times'
        }
      }
      return this.icon
    }
  }
}
</script>
