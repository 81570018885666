/* eslint-disable no-useless-constructor */
import feathersClient, { makeServicePlugin, BaseModel } from '../../api'
import selbstbehaltTK from '@/resources/enums/selbstbehaltTK.js'
import selbstbehaltVK from '@/resources/enums/selbstbehaltVK.js'

class HsOrder extends BaseModel {
  constructor (data, options) {
    super(data, options)
  }

  // Required for FeathersVuex plugin to work after production transpile.
  static modelName = 'HsOrder'

  static instanceDefaults () {
    return {
      owner: {
        title: {},
        country: {},
        city: {}
      },
      abwKontoinhaber: {},
      anfrage: false,
      ablehnung: false,
      carData: {},
      classicCar: null,
      productPackages: {
        KOMFORT: {
          weltweiterVersicherungsschutzKasko: 'no',
          zusatzpaketFreiesFahren: 'no',
          selbstbehalt: selbstbehaltVK.TK150VK500.api,
          schutzpacketFahrerschutz: false,
          plusVolllasko: false,
          zusatzpacketElektro: false
        },
        EXCLUSIV: {
          weltweiterVersicherungsschutzKasko: 'no',
          zusatzpaketFreiesFahren: 'no',
          selbstbehalt: selbstbehaltVK.TK150VK500.api,
          schutzpacketFahrerschutz: false,
          plusVolllasko: false,
          zusatzpacketElektro: false
        },
        KOMPAKT: {
          selbstbehalt: selbstbehaltTK.TK150.api,
          schutzpacketFahrerschutz: false
        }
      },
      wertnachweisFiles: []
    }
  }
}
const servicePath = 'hs-order'
const servicePlugin = makeServicePlugin({
  Model: HsOrder,
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
