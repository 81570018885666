<template>
  <div class="position-fixed bottom-0 left-0 right-0 z-1035">
    <Transition name="slide-up">
      <div
        v-if="showCookieBanner"
        class="bg-white rounded-lg shadow-lg p-4 m-5"
      >
        <div class="d-flex align-items-center">
          <p class="flex-grow mb-0">
            Wir nutzen Cookies, um Ihnen ein optimales Besuchererlebnis zu bieten. Hiervon eingeschlossen sind sowohl Cookies, welche für den Betrieb unserer Webseite und zur Verfolgung unserer geschäftlichen Interessen erforderlich sind, als auch Cookies, welche für anonymisierte Statistiken, Benutzerfeatures und die Darstellung von personalisierten Inhalten verwendet werden. Sie können allen Cookies und Datenverarbeitungen zustimmen, indem Sie den "Akzeptieren" Button klicken oder Ihre Zustimmung durch Klick auf „Ablehnen“ verweigern.
          </p>
          <button
            class="btn btn-primary text-white mr-3 ml-3"
            @click="acceptCookie()"
          >
            Akzeptieren
          </button>
          <button
            class="btn btn-secondary"
            @click="declineCookie()"
          >
            Ablehnen
          </button>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  data: () => ({
    showCookieBanner: false
  }),
  mounted () {
    this.showCookieBanner = false
    this.setCookieBanner()
  },
  methods: {
    acceptCookie () {
      this.$gtm.enable(true)
      window.localStorage.setItem('cookieAccepted', true)
      this.showCookieBanner = false
    },
    declineCookie () {
      window.localStorage.setItem('cookieAccepted', false)
      this.showCookieBanner = false
    },
    setCookieBanner () {
      if (process && process.env && process.env.NODE_ENV === 'production') {
        const cookieAccepted = window.localStorage.getItem('cookieAccepted')
        if (cookieAccepted && cookieAccepted === 'true') {
          this.$gtm.enable(true)
          this.showCookieBanner = false
        } else if (cookieAccepted && cookieAccepted === 'false') {
          this.showCookieBanner = false
        } else this.showCookieBanner = true
      }
    }
  }
}
</script>

<style lang="scss">

.z-1035 {
  z-index: 1035;
}

</style>
