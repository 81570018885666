export default [
  {
    name: 'CarList',
    path: '/fahrzeugbestand',
    component: () => import('./CarList.vue')
  },
  {
    name: 'CarCreate',
    path: '/fahrzeugbestand/create',
    component: () => import('./CarCreate.vue')
  },
  {
    name: 'CarDetail',
    path: '/fahrzeugbestand/:carId',
    component: () => import('./CarDetail.vue')
  },
  {
    name: 'CarEdit',
    path: '/fahrzeugbestand/:carId/edit',
    component: () => import('./CarEdit.vue')
  },
  {
    name: 'CarAdditionalData',
    path: '/fahrzeugbestand/:carId/zusaetzlicheDaten/create',
    component: () => import('./CarAdditionalData.vue')
  },
  {
    name: 'CarPool',
    path: '/fahrzeugpool',
    component: () => import('./CarPool.vue')
  },
  {
    name: 'CarFavoriteList',
    path: '/fahrzeugpool/favorites',
    component: () => import('./CarFavoriteList.vue')
  },
  {
    name: 'CarPoolDetail',
    path: '/fahrzeugpool/:carId',
    component: () => import('./CarPoolDetail.vue')
  }
]
