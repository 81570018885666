export default [
  {
    name: 'BlacklistedChassisList',
    path: '/blacklist/chassis',
    component: () => import('./BlacklistedChassisList')
  },
  {
    name: 'BlacklistedChassisReasonsList',
    path: '/blacklist/reasons',
    component: () => import('./BlacklistedChassisList')
  },
  {
    name: 'BlacklistedChassisCreate',
    path: '/blacklist/chassis/create',
    component: () => import('./BlacklistedChassisCreate')
  },
  {
    name: 'BlacklistedChassisEdit',
    path: '/blacklist/chassis/:blacklistedChassisId',
    component: () => import('./BlacklistedChassisEdit')
  }
]
