<template>
  <div
    class="sidebar"
    :class="{'collapsed': collapse}"
  >
    <router-link :to="{ name: 'HSSOrderCreate' }">
      <div class="logo-wrapper bg-primary-gradient d-flex align-items-center justify-content-center">
        <transition name="fade-small">
          <img
            v-if="collapse"
            class="logo-small"
            src="@/assets/img/hs_hori_logo_small_blue.png"
            alt=""
            @click="routerFrouter"
          >
        </transition>
        <transition name="fade-big">
          <img
            v-if="!collapse"
            class="mr-2"
            src="@/assets/img/hs_hori_logo_blue.png"
            alt=""
          >
        </transition>
      </div>
    </router-link>
    <sidebar-menu
      :menu="menu"
      :show-one-child="true"
      :width="'250px'"
      class="sidebar-nav"
      @collapse="onCollapse"
      @item-click="itemClick"
    />
  </div>
</template>

<script>
import hsOrderTypes from '@/resources/enums/hsOrderTypes'
import { SidebarMenu } from 'vue-sidebar-menu'

export default {
  name: 'SideMenu',
  components: {
    SidebarMenu
  },
  data () {
    return {
      collapse: null,
      extendTotal: null,
      gvcTotal: null
    }
  },
  computed: {
    canUseCar () {
      return this.me && this.me.subscriptions && this.me.subscriptions.some(
        subscription => new Date(subscription.from) <= Date.now() && new Date(subscription.to) >= Date.now() && subscription.type === 'CAR'
      )
    },
    canUseCarPool () {
      return this.me && this.me.subscriptions && this.me.subscriptions.some(
        subscription => new Date(subscription.from) <= Date.now() && new Date(subscription.to) >= Date.now() && subscription.type === 'CAR_POOL'
      )
    },
    menu () {
      const menu = []
      menu.push({
        header: true,
        title: 'Navigation'
      })

      if (this.$can('seeOwnDashboard')) {
        menu.push({
          title: 'Dashboard',
          icon: 'far fa-tachometer-fast',
          href: { name: 'Dashboard' }
        })
      }
      if (this.$can('createPolicyAntrag') || this.$can('createCreatePolicyAntragIgnoringAnfrageReasons') || this.$can('createPolicyAnfrage') || this.$can('createPolicyAngebot')) {
        menu.push(
          {
            href: { name: 'HSSOrderCreate' },
            title: 'Tarifrechner',
            icon: 'far fa-plus'
          }
        )
      }
      if (this.$can('seeAllPolicyAnfragen') || this.$can('seeOwnPolicyAnfragen')) {
        menu.push(
          {
            href: { name: 'HSSAnfrageList', params: { type: hsOrderTypes.ANFRAGE.api } },
            title: 'Anfragen',
            icon: 'far fa-file-signature'
          }
        )
      }
      if (this.$can('seeAllPolicyAngebote') || this.$can('seeOwnPolicyAngebote')) {
        menu.push(
          {
            href: { name: 'HSSAngebotList', params: { type: hsOrderTypes.ANGEBOT.api } },
            title: 'Angebote',
            icon: 'far fa-file-signature'
          }
        )
      }
      if (this.$can('seeAllPolicyAntraege') || this.$can('seeOwnPolicyAntraege')) {
        menu.push(
          {
            href: { name: 'HSSAntragList', params: { type: hsOrderTypes.ANTRAG.api } },
            title: 'Anträge',
            icon: 'far fa-file-signature'
          }
        )
      }
      if (this.$can('seeAllPolicyVertraege') || this.$can('seeOwnPolicyVertraege')) {
        menu.push(
          {
            href: { name: 'HSSVertragList', params: { type: hsOrderTypes.VERTRAG.api } },
            title: 'Verträge',
            icon: 'far fa-file-signature'
          }
        )
      }

      if (this.$can('seeAllCustomers') || this.$can('seeOwnCustomers')) {
        menu.push({
          href: { name: 'CustomerList' },
          title: 'Kunden',
          icon: 'far fa-user-tag'
        })
      }

      if (this.$can('seeWiedervorlagen')) {
        menu.push({
          href: { name: 'ResubmitList' },
          title: 'Wiedervorlagen',
          icon: 'far fa-clipboard-list-check'
        })
      }
      // menu.push({
      //   href: { name: 'NewsOverview' },
      //   title: 'News',
      //   icon: 'far fa-newspaper'
      // })

      if (this.$can('seeAllUsers') || this.$can('seeCalcuationData') || this.$can('seeLogs') || this.$can('seeClientConfig')) {
        menu.push(
          {
            header: true,
            title: 'Verwaltung'
          }
        )
      }
      if (this.$can('seeAllUsers')) {
        menu.push(
          {
            href: { name: 'UserList' },
            title: 'Benutzer',
            icon: 'far fa-users'
          }
        )
      }
      if (this.$can('crudAgentGroups')) {
        menu.push(
          {
            href: { name: 'AgentGroupList' },
            title: 'Vermittlergruppen',
            icon: 'far fa-user-friends'
          }
        )
      }
      if (this.$can('seeCalcuationData')) {
        menu.push(
          {
            href: { name: 'HSSDataEdit' },
            title: 'Tabellenpflege',
            icon: 'far fa-car'
          }
        )
      }
      if (this.$can('seeLogs')) {
        menu.push(
          {
            href: { name: 'OrderLog' },
            title: 'Log',
            icon: 'far fa-list'
          }
        )
      }
      if (this.$can('seeClientConfig') && this.$can('editClientConfig')) {
        menu.push(
          {
            href: { name: 'Settings' },
            title: 'System',
            icon: 'far fa-cogs'
          }
        )
      }
      if (this.$can('seeVersichererabrechnungen')) {
        menu.push(
          {
            href: { name: 'Versichererabrechnung' },
            title: 'Versichererabrechnungen',
            icon: 'far fa-analytics'
          }
        )
      }
      return menu
    },
    me () {
      return this.$store.getters['auth/user'].user
    }
  },
  methods: {
    onCollapse (isCollapsed) {
      this.collapse = isCollapsed
      this.$emit('collapse', isCollapsed)
    },
    async itemClick (event) {
      // hack to logout becauce the menu has no direct click listener
      if (event.target.innerHTML === 'Ausloggen') {
        await this.$store.dispatch('auth/logout')
        await this.$router.push({ name: 'Login' })
      }
    }
  }
}
</script>

<style lang="scss">
  @import "node_modules/vue-sidebar-menu/src/scss/vue-sidebar-menu.scss";
  @import "@/assets/scss/_variables.scss";

  .fade-big-enter-active {
    transition: all 100ms ease;
    width: 0;
  }
  .fade-big-enter, .fade-big-leave-to
  /* .fade-big-leave-active below version 2.1.8 */ {
    width: 0;
  }

  .sidebar {
    z-index: 999;
    transition: all 0.2s ease;
    height: 100vh;
    position: fixed;
    z-index: 1030;
    &.collapsed{
      .logo-wrapper{
        width: 50px;
        transition: all 300ms ease;
      }
    }
    .logo-wrapper{
      height: 67.59px;
      width: 250px;
      transition: all 300ms ease;
      >img{
        height: 38px;
      }
      >img.logo-small{
        height: 38px;
      }
    }
    .footer{
      height: 30px;
      .copyright-big{
        overflow: hidden;
      }
    }

    .v-sidebar-menu {
      background: $sidebar-submenu-background;
      color: #fff;
      z-index: 999;
      position: relative;
      height: calc(100% - 67.59px);
      padding: 0;
      font-size: 14px;

      .vsm-list{
        &::-webkit-scrollbar-track {
          border-radius: 8px;
          background-color: transparent;
        }

        &::-webkit-scrollbar {
          width: 6px;
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: rgba(white, 0.5);
        }

        .vsm-header {
          font-size: 12px;
          color: $sidebar-submenu-color !important;
          margin-top: 20px;
          &:first-child{
            margin-top:0;
          }
        }

        .vsm-item {
          .vsm-link {
            color: $sidebar-submenu-color !important;
            padding: 10px;
            text-decoration: none;
            display: block;
            font-weight: 100;
            font-size: 14px;
            position: relative;

            &:hover {
              color: $sidebar-submenu-color !important;
              background-color: darken($sidebar-submenu-background, 5) !important;
              text-decoration: none !important;
            }

            .vsm-badge{
              display: inline-block;
              padding: .25em .4em;
              font-size: 75%;
              font-weight: 700;
              line-height: 1;
              text-align: center;
              white-space: nowrap;
              vertical-align: baseline;
              border-radius: .25rem;
            }

            .vsm-arrow {
              margin-right: 10px;
              transition: none;
              width: 20px;
              color: inherit;

              &::after {
                color: $sidebar-submenu-color;
                font-style: normal;
                font-size: 0.85rem;
                font-weight: bold;
                content: "\f0da";
                float: right;
                padding-right: 10px;
                font-family: "Font Awesome 5 Pro";
              }

              &.open-arrow {
                -webkit-transform: translate(-20%, -40%) rotate(90deg);
                transform: translate(-20%, -40%) rotate(90deg);
              }
            }
          }

          &.first-item {
            .vsm-link {
              .vsm-icon {
                color: $sidebar-submenu-color !important;
                background: none;
              }
            }

            &.active-item, &.parent-active-item {
              > .vsm-link {
                box-shadow: inset 3px 0 0 0 $sidebar-submenu-color;
              }
            }
          }
        }
        .vsm-item.first-item.open-item > .vsm-link {
          background-color: $sidebar-submenu-left-border-color;
        }
        .vsm-item.first-item.open-item, .vsm-dropdown > .vsm-list, .vsm-mobile-bg {
          background: darken($sidebar-submenu-background, 3);
        }

        .vsm-dropdown {
          .vsm-list {
            border-left:5px solid $sidebar-submenu-left-border-color;
            .vsm-item {
              a {
              }
            }
          }
        }

      }

      .collapse-btn {

        &::after {
          content: "\f100";
          font-family: "Font Awesome 5 Pro";
        }
        &:focus{
          outline: none;
        }
      }
      &.vsm-collapsed {
        .collapse-btn {
          &::after {
            content: "\f101";
            font-family: "Font Awesome 5 Pro";
          }
        }
      }
    }
  }
</style>
