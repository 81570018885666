export default [
  {
    name: 'AgentGroupList',
    path: '/agentGroup/',
    component: () => import('./AgentGroupList.vue')
  },
  {
    name: 'AgentGroupCreate',
    path: '/agentGroup/create',
    component: () => import('./AgentGroupForm')
  },
  {
    name: 'AgentGroupEdit',
    path: '/agentGroup/:agentGroupId',
    component: () => import('./AgentGroupForm')
  }
]
