export default [
  {
    name: 'HsnTsnList',
    path: '/hsntsn',
    component: () => import('./HsnTsnList'),
    meta: {
      layout: 'RateListsLayout'
    }
  },
  {
    name: 'HsnTsnCreate',
    path: '/hsntsn/create',
    component: () => import('./HsnTsnCreate')
  },
  {
    name: 'HsnTsnEdit',
    path: '/hsntsn/:hsntsnId',
    component: () => import('./HsnTsnEdit')
  }
]
