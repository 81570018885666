<template>
  <div id="app">
    <CookieBanner />
    <router-view />
  </div>
</template>

<script>
import CookieBanner from '@/components/CookieBanner.vue'

export default {
  components: {
    CookieBanner
  }
}
</script>

<style lang="scss">
</style>
