module.exports = {
  TK0VK0: { api: 'TK0VK0', human: 'VK 0€ / TK 0€', vk: 0, tk: 0 },
  TK0VK150: { api: 'TK0VK150', human: 'VK 150€ / TK 0€', vk: 150, tk: 0 },
  TK0VK300: { api: 'TK0VK300', human: 'VK 300€ / TK 0€', vk: 300, tk: 0 },
  TK0VK500: { api: 'TK0VK500', human: 'VK 500€ / TK 0€', vk: 500, tk: 0 },
  TK0VK1000: { api: 'TK0VK1000', human: 'VK 1.000€ / TK 0€', vk: 1000, tk: 0 },
  TK0VK2500: { api: 'TK0VK2500', human: 'VK 2.500€ / TK 0€', vk: 2500, tk: 0 },
  TK0VK5000: { api: 'TK0VK5000', human: 'VK 5.000€ / TK 0€', vk: 5000, tk: 0 },
  TK150VK150: { api: 'TK150VK150', human: 'VK 150€ / TK 150€', vk: 150, tk: 150 },
  TK150VK300: { api: 'TK150VK300', human: 'VK 300€ / TK 150€', vk: 300, tk: 150 },
  TK150VK500: { api: 'TK150VK500', human: 'VK 500€ / TK 150€', vk: 500, tk: 150 },
  TK150VK1000: { api: 'TK150VK1000', human: 'VK 1.000€ / TK 150€', vk: 1000, tk: 150 },
  TK150VK2500: { api: 'TK150VK2500', human: 'VK 2.500€ / TK 150€', vk: 2500, tk: 150 },
  TK150VK5000: { api: 'TK150VK5000', human: 'VK 5.000€ / TK 150€', vk: 5000, tk: 150 },
  TK500VK500: { api: 'TK500VK500', human: 'VK 500€ / TK 500€', vk: 500, tk: 500 },
  TK1000VK1000: { api: 'TK1000VK1000', human: 'VK 1.000€ / TK 1.000€', vk: 1000, tk: 1000 },
  TK2500VK2500: { api: 'TK2500VK2500', human: 'VK 2.500€ / TK 2.500€', vk: 2500, tk: 2500 },
  TK5000VK5000: { api: 'TK5000VK5000', human: 'VK 5.000€ / TK 5.000€', vk: 5000, tk: 5000 },
  TK10000VK10000: { api: 'TK10000VK10000', human: 'VK 10.000€ / TK 10.000€', vk: 10000, tk: 10000 },
  TK25000VK25000: { api: 'TK25000VK25000', human: 'VK 25.000€ / TK 25.000€', vk: 25000, tk: 25000 },
  TK50000VK50000: { api: 'TK50000VK50000', human: 'VK 50.000€ / TK 50.000€', vk: 50000, tk: 50000 },
  TK100000VK100000: { api: 'TK100000VK100000', human: 'VK 100.000€ / TK 100.000€', vk: 100000, tk: 100000 }
}
