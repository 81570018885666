import Vue from 'vue'
import Vuex from 'vuex'

import { FeathersVuex } from '../api'
import auth from './store.auth'

Vue.use(Vuex)
Vue.use(FeathersVuex)

const requireModule = require.context(
  // The path where the service modules live
  './services',
  // Whether to look in subfolders
  false,
  // Only include .js files (prevents duplicate imports`)
  /.js$/
)
const servicePlugins = requireModule
  .keys()
  .map(modulePath => requireModule(modulePath).default)

const store = new Vuex.Store({
  state: {
    commitHash: process.env.VUE_APP_COMMIT_HASH || 'xxxx',
    version: process.env.VUE_APP_VERSION || 'x.x.x',
    system: process.env.VUE_APP_SYSTEM,
    cubejsMeta: null,
    pushNotificationServerKey: process.env.VUE_APP_PUSH_NOTIFICATION_SERVER_KEY || 'BNaQR9djnuLhTFJqVPQxQwzLDe9dB7uVD6DybHEO6XCRVBBUAeufNwCCA36U0BFpPPqFj0nvP2kpgMh5iIO9QFk'
  },
  plugins: [...servicePlugins, auth]
})
if (window.Cypress) {
  window.__store__ = store
}

export default store
