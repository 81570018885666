/* eslint-disable no-useless-constructor */
import feathersClient, { makeServicePlugin, BaseModel } from '../../api'

class Workshop extends BaseModel {
  constructor (data, options) {
    super(data, options)
  }

  // Required for FeathersVuex plugin to work after production transpile.
  static modelName = 'Workshop'
  // Define default properties here
  static instanceDefaults () {
    return {
      PARTNERNR: null,
      address: {
        NAME: {
        },
        contact: {
        },
        NAT: {
        }
      },
      address2: {
        contact: {
        },
        NAT2: {
          char: '',
          name: ''
        }
      },
      information: {
        bankId: []
      }
    }
  }
}
const servicePath = 'workshops'
const servicePlugin = makeServicePlugin({
  Model: Workshop,
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
