<template>
  <footer class="footer mt-auto">
    <div class="container text-center">
      &copy; {{ currentYear }} <a href="https://herzenssache.eu">Herzensache (v{{ $store.state.version }}-#{{ $store.state.commitHash }}-{{ $store.state.system }}) </a>
    </div>
  </footer>
</template>

<script>
export default {
  data: () => ({
    currentYear: new Date().getFullYear()
  })
}
</script>

<style lang="scss">
@import '../../assets/scss/_variables.scss';

footer {
  font-size: 13px;
  line-height: 1.66666667;
  padding: 15px 10px;
  font-weight: 300;
  a {
    color: inherit !important;
    display: inline;
    padding: 0;

    &:hover {
      text-decoration: underline !important;
    }
  }
}
</style>
