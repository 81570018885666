export default [
  {
    name: 'Damage',
    path: '/damage/',
    redirect: 'damage/list/all'
  },
  {
    name: 'DamageReportListOrderId',
    path: '/damage/list/order/:orderId',
    component: () => import('./DamageReportList')
  },
  {
    name: 'DamageReportListUser',
    path: '/damage/list/user/:userId',
    component: () => import('./DamageReportList')
  },
  {
    name: 'DamageReportListAll',
    path: '/damage/list/all',
    component: () => import('./DamageReportList')
  },
  {
    name: 'DamageReportCreate',
    path: '/damage/report',
    component: () => import('./DamageReportCreate')
  },
  {
    name: 'DamageReportCreateOrderId',
    path: '/damage/report/:orderId',
    component: () => import('./DamageReportCreate')
  },
  {
    name: 'DamageReportEdit',
    path: '/damage/edit/:reportId',
    component: () => import('./DamageReportEdit')
  },
  {
    name: 'DamageReportDetail',
    path: '/damage/detail/:reportId',
    component: () => import('./DamageReportDetail')
  }
]
