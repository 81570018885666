export default [
  {
    name: 'KaufschutzRateList',
    path: '/kaufschutz-rate',
    component: () => import('./KaufschutzRateList'),
    meta: {
      layout: 'RateListsLayout'
    }
  },
  {
    name: 'KaufschutzRateCreate',
    path: '/kaufschutz-rate/create',
    component: () => import('./KaufschutzRateCreate')
  },
  {
    name: 'KaufschutzRateEdit',
    path: '/kaufschutz-rate/:kaufschutzRateId',
    component: () => import('./KaufschutzRateEdit')
  }
]
