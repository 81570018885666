export default [
  {
    name: 'UserList',
    path: '/user/',
    component: () => import('./UserList.vue')
  },
  {
    name: 'UserCreate',
    path: '/user/create',
    component: () => import('./UserCreate')
  },
  {
    name: 'UserEdit',
    path: '/user/:userId',
    component: () => import('./UserEdit')
  }
]
