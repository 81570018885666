import feathers from '@feathersjs/client'
import socketio from '@feathersjs/client/socketio'
import auth from '@feathersjs/client/authentication'
import io from 'socket.io-client'
import feathersVuex from 'feathers-vuex'

const socket = io('/', { transports: ['websocket'], path: '/api/socket.io' })

const feathersClient = feathers()
  .configure(socketio(socket, {
    timeout: 60000
  }))
  .configure(auth({ storage: window.localStorage }))

export default feathersClient

// Setting up feathers-vuex
const { makeServicePlugin, makeAuthPlugin, BaseModel, models, FeathersVuex } = feathersVuex(
  feathersClient,
  {
    idField: '_id',
    whitelist: ['$regex', '$options']
  }
)

export { makeAuthPlugin, makeServicePlugin, BaseModel, models, FeathersVuex }
