/* eslint-disable no-useless-constructor */
import feathersClient, { makeServicePlugin, BaseModel } from '../../api'
const system = process.env.VUE_APP_SYSTEM
let country
if (system === 'at') {
  country = () => ({ name: 'Österreich', char: 'A' })
} else {
  country = () => ({ name: 'Deutschland', char: 'D' })
}

class Car extends BaseModel {
  constructor (data, options) {
    super(data, options)
  }

  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'Car'
  // Define default properties here
  static instanceDefaults () {
    return {
      country: country(),
      noHsnTsn: false,
      milageMeasureDate: new Date(),
      gasUpcharge: false
    }
  }

  static setupInstance (data, { models }) {
    if (data.carAdditionalInfo) {
      data.carAdditionalInfo = new models.api.CarAdditionalInfo(data.carAdditionalInfo)
    }
    return data
  }
}
const servicePath = 'car'
const servicePlugin = makeServicePlugin({
  Model: Car,
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
