import VeeValidate, { Validator } from 'vee-validate'
import iban from 'iban'
import de from 'vee-validate/dist/locale/de'
import moment from 'moment'

de.messages.max_value = (field, maxValue) => {
  return `${field} darf nicht größer sein als ${maxValue}`
}

de.messages.min_value = (field, minValue) => {
  return `${field} darf nicht kleiner sein als ${minValue}`
}

const validateDisabledDates = {
  getMessage () {
    return 'Das Datum liegt außerhalb des validen Zeitraums'
  },
  validate (value, { disabledDates, format }) {
    if (!disabledDates || (!disabledDates.from && !disabledDates.to)) return true
    const parsedDate = parseDate(value, format)
    if (disabledDates.to && parsedDate.isBefore(disabledDates.to)) {
      return false
    }
    if (disabledDates.from && parsedDate.isAfter(disabledDates.from)) {
      return false
    }
    return true
  }
}

Validator.localize('de', de)
Validator.extend('iban', iban.isValid)
Validator.extend('disabled-dates', validateDisabledDates)

export default function (Vue) {
  Vue.use(VeeValidate, {
    locale: 'de',
    invalidateFalse: true,
    inject: false,
    fieldsBagName: 'veeFields'
  })
}

function parseDate (dateStr, format) {
  const parsed = moment(dateStr, format)
  if (parsed.isValid()) {
    return parsed
  }
}
