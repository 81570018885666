export default [
  {
    name: 'News',
    path: '/news/',
    redirect: 'news/list'
  },
  {
    name: 'NewsList',
    path: '/news/list',
    component: () => import('./NewsList')
  },
  {
    name: 'NewsEdit',
    path: '/news/:newsId/edit',
    component: () => import('./NewsEdit')
  },
  {
    name: 'NewsCreate',
    path: '/news/create',
    component: () => import('./NewsCreate')
  },
  {
    name: 'NewsOverview',
    path: '/news/overview',
    component: () => import('./NewsOverview')
  }
]
