import store from './store/'

export default async function redirectHook (to, from, next) {
  let id = null
  let type = null
  if (to.params.warrentyNumber.includes('-')) {
    const hn = to.params.warrentyNumber.split('-')[0]
    const warrentyNumber = to.params.warrentyNumber.split('-')[1]
    const orders = (await store.dispatch('orders/find', {
      query: {
        'product.rate.product.hn': hn,
        'contract.warrantyNumber': warrentyNumber
      }
    })).data
    if (orders[0] !== undefined) {
      id = orders[0]._id
      type = 'order'
    }
  } else {
    const warrentyNumber = to.params.warrentyNumber
    const orders = (await store.dispatch('orders/find', {
      query: {
        'contract.warrantyNumber': warrentyNumber
      }
    })).data
    if (orders[0] !== undefined) {
      id = orders[0]._id
      type = 'order'
    }
  }

  if (id != null && type === 'order') return next({ name: 'OrderDetail', params: { orderId: id } })
  else return next('/')
}
