export default [
  {
    name: 'GvcPdf',
    path: '/gvc',
    component: () => import('./GvcPdf.vue')
  },
  {
    name: 'Settings',
    path: '/settings',
    component: () => import('./Settings')
  },
  {
    name: 'OrderLog',
    path: '/order-log',
    meta: {
      isGlobalLogList: true
    },
    component: () => import('./OrderLog')
  },
  {
    name: 'OrderLogDetail',
    path: '/order-log/:orderLogId',
    component: () => import('./OrderLogDetail')
  }
]
