export default [
  {
    path: '/customer/',
    redirect: 'customer/list'
  },
  {
    name: 'CustomerList',
    path: '/customer/list',
    component: () => import('./CustomerList')
  },
  {
    name: 'CustomerCreate',
    path: '/customer/create',
    component: () => import('./CustomerCreate')
  },
  {
    name: 'CustomerEdit',
    path: '/customer/edit/:customerId',
    component: () => import('./CustomerCreate')
  },
  {
    name: 'CustomerDetail',
    path: '/customer/detail/:customerId',
    component: () => import('./CustomerDetail')
  }
]
