export default [
  {
    name: 'AgbList',
    path: '/agb',
    component: () => import('./AgbList.vue')
  },
  {
    name: 'AgbEditorNew',
    path: '/agb/editor',
    component: () => import('./AgbEditor.vue')
  },
  {
    name: 'AgbEditorEdit',
    path: '/agb/editor/:agbId',
    component: () => import('./AgbEditor.vue')
  }
]
