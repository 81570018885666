<template>
  <div class="wrapper">
    <ca-alert
      v-if="client && client.announcementText"
      class="welcome-alert"
      icon="info"
      :message="client.announcementText"
      not-closeable
    />
    <div class="d-flex justify-content-center align-items-center height">
      <div class="col-12 col-sm-8 col-md-6 col-xl-4 col-xxl-3 col-xxxl-2">
        <slot><router-view /></slot>
        <layout-footer />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";
.wrapper{
  position: relative;
  .welcome-alert{
    position: absolute;
    top: 0;
  }
}
.height{
  height: 100vh;
}

</style>

<script>
import LayoutFooter from '@/components/Layout/LayoutFooter.vue'
export default {
  name: 'CenterLayout',
  components: {
    LayoutFooter
  },
  data: () => ({
    client: null
  }),
  watch: {
    $route: {
      immediate: true,
      handler: 'fetchData'
    }
  },
  methods: {
    async fetchData () {
      try {
        this.loading = true
        const clientReq = await this.$store.dispatch('clients/find', {
          query: {
            $limit: 1
          }
        })
        this.client = clientReq.data[0]
      } catch (error) {
        this.loadingError = error
        console.error(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
